import React from 'react';
import Dashboard from '../../components/klinik/Dashboard';
import Layout from '../../pages/klinik/Layout';

export const DashboardPage = () => {
    return (
        <Layout>
            <Dashboard />
        </Layout>
    )
}
export default DashboardPage;