const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const refreshAccessToken = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/auth/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error("Failed to refresh token");
        }

        const data = await response.json();
        return data.accessToken;
    } catch (error) {
        console.error("Error refreshing access token:", error);
        throw error;
    }
};

export const fetchData = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/klinik/pasien-rujukan`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (response.status === 401) {
            const newAccessToken = await refreshAccessToken();
            return fetchDataWithNewToken(newAccessToken);
        }

        if (!response.ok) {
            throw new Error("Failed to fetch data!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

const fetchDataWithNewToken = async (newAccessToken) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/klinik/pasien-rujukan`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newAccessToken}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error("Failed to fetch data with new token!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error fetching data with new token:", error);
        throw error;
    }
};

export const fetchPasienRujukanHistory = async (NOPASIEN) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/klinik/pasien-rujukan-history/${NOPASIEN}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        });

        if (response.status === 401) {
            const newAccessToken = await refreshAccessToken();
            return fetchPasienRujukanHistoryWithNewToken(newAccessToken, NOPASIEN);
        }

        if (!response.ok) {
            throw new Error("Failed to fetch data!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error fetching patient history:", error);
        throw error;
    }
};

const fetchPasienRujukanHistoryWithNewToken = async (newAccessToken, NOPASIEN) => {
    try {
        const response = await fetch(`${API_BASE_URL}/api/v1/klinik/pasien-rujukan-history/${NOPASIEN}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${newAccessToken}`,
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error("Failed to fetch data with new token!");
        }

        const data = await response.json();
        return data.payload;
    } catch (error) {
        console.error("Error fetching patient history with new token:", error);
        throw error;
    }
};