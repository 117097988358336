import React from 'react';
import Tabs from '@mui/material/Tabs';
import { GlobalStyles } from '@mui/system';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    // faTachometerAlt,
    faPaperPlane,
    // faMobileAlt,
    // faImages,
    // faTags,
    // faNewspaper,
    // faVideo,
    // faAddressBook,
} from '@fortawesome/free-solid-svg-icons';

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const CustomTab = ({ label, icon, selected, onClick, ...props }) => (
    <Tab
        label={label}
        icon={
            <Box
                sx={{
                    width: 50,
                    height: 50,
                    backgroundColor: selected ? '#E0E4FA' : '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '10px',
                    // boxShadow: selected ? '0px 0px 8px rgba(0, 0, 0, 0.15)' : 'none',
                }}
            >
                <FontAwesomeIcon
                    icon={icon}
                    style={{ width: 20, height: 20, color: selected ? '#050C9C' : '#888' }}
                />
            </Box>
        }
        onClick={onClick}
        {...props}
        sx={{
            '& .MuiTab-wrapper': {
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
            },
            color: selected ? '#102C57' : '#888',
            '& svg': {
                fill: 'currentColor',
            },
        }}
    />
);


export const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const role = localStorage.getItem('role');

    const tabs = [
        // { label: '', icon: faTachometerAlt, route: '/klinik/dashboard', allowedRoles: ['admin', 'user'] },
        { label: '', icon: faPaperPlane, route: '/klinik/data-rujukan', allowedRoles: ['admin'] },
        // { label: '', icon: faMobileAlt, route: '/klinik/dashboard', allowedRoles: ['admin'] },
        // { label: '', icon: faImages, route: '/klinik/dashboard', allowedRoles: ['admin'] },
        // { label: '', icon: faTags, route: '/klinik/dashboard', allowedRoles: ['admin'] },
        // { label: '', icon: faNewspaper, route: '/klinik/dashboard', allowedRoles: ['admin'] },
        // { label: '', icon: faVideo, route: '/klinik/dashboard', allowedRoles: ['admin'] },
        // { label: '', icon: faAddressBook, route: '/klinik/dashboard', allowedRoles: ['admin'] },
    ];

    const currentTabValue = tabs.findIndex(tab => tab.route === location.pathname);

    return (
        <Box sx={{
            display: 'flex',
            height: '100vh',
            overflowY: 'hidden',
            backgroundColor: '#fff',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <GlobalStyles
                styles={{
                    '*::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '*::-webkit-scrollbar-track': {
                        background: '#f1f1f1',
                        width: '12px',
                    },
                    '*::-webkit-scrollbar-thumb': {
                        background: '#888',
                        borderRadius: '4px',
                    },
                    '*::-webkit-scrollbar-button:start:decrement, *::-webkit-scrollbar-button:end:increment': {
                        display: 'block',
                        height: '12px',
                        width: '100%',
                        background: '#f1f1f1',
                    },
                    '*::-webkit-scrollbar-button:start:decrement': {
                        backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 12 12\'%3E%3Cpolygon points=\'0,10 6,2 12,10\' fill=\'%23888\'/%3E%3C/svg%3E")',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    },
                    '*::-webkit-scrollbar-button:end:increment': {
                        backgroundImage: 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 12 12\'%3E%3Cpolygon points=\'0,2 6,10 12,2\' fill=\'%23888\'/%3E%3C/svg%3E")',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                    },
                }}
            />
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={currentTabValue}
                aria-label="Vertical tabs example"
                sx={{
                    borderRight: 'none',
                    '& .MuiTabs-indicator': {
                        left: 0,
                        right: 'auto',
                        width: '5px',
                        backgroundColor: '#050C9C'
                    },
                    '& .MuiTab-root': {
                        minWidth: '75px',
                        maxWidth: '75px',
                        fontSize: '0.6rem',
                        textTransform: 'none',
                    },
                    height: 'auto'
                }}
            >
                {tabs.map((tab, index) => (
                    <CustomTab
                        key={tab.label}
                        label={tab.label}
                        icon={tab.icon}
                        selected={location.pathname === tab.route}
                        onClick={() => navigate(tab.route)}
                        disabled={!tab.allowedRoles.includes(role)}
                        {...a11yProps(index)}
                    />
                ))}
            </Tabs>
        </Box>
    );
};

export default Navbar;