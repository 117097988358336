import React, { useState, useEffect } from 'react';
import { Tooltip as Tooltip2, IconButton, TextField, Box, Table, TableBody, TableCell, Divider, TableContainer, TableHead, TableRow, TablePagination, Grid2, Card, Modal, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { fetchData, fetchPasienRujukanHistory } from '../../api/klinikAPI';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend, Filler, LinearScale, CategoryScale, LineElement, PointElement, BarElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup, faVenusMars, faUser, faEye, faPaperPlane, faPhone, faLocationDot, faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import RefreshIcon from '@mui/icons-material/Refresh';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DownloadIcon from '@mui/icons-material/Download';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import * as XLSX from 'xlsx';

Chart.register(ArcElement, Tooltip, Legend, Filler, LinearScale, CategoryScale, LineElement, PointElement, BarElement);

const monthNames = [
    'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
    'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
];

const columns = [
    { id: 'TGLREG', label: 'Tanggal', minWidth: 80 },
    { id: 'NAMAPASIEN', label: 'Nama', minWidth: 170 },
    { id: 'NAMAPIHAK3', label: 'Penjamin', minWidth: 170 },
    { id: 'Keterangan', label: 'Dirujuk', minWidth: 170 },
    { id: 'DPJP', label: 'Dokter', minWidth: 170 },
    { id: 'actions', label: 'Aksi', minWidth: 80 }
];

const DataPerujuk = () => {
    const today = new Date().toISOString().split('T')[0];
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openModalRumahSakit, setOpenModalRumahSakit] = useState(false);
    const [modalDataRumahSakit, setModalDataRumahSakit] = useState(null);
    const [openModalPenjamin, setOpenModalPenjamin] = useState(false);
    const [modalDataPenjamin, setModalDataPenjamin] = useState(null);
    const [openModalPelayanan, setOpenModalPelayanan] = useState(false);
    const [openModalPasien, setOpenModalPasien] = useState(false);
    const [modalDataPasien, setModalDataPasien] = useState(null);
    const [openModalNotifikasi, setOpenModalNotifikasi] = useState(false);
    const [monthlyData, setMonthlyData] = useState({});
    const [monthlyDataRS, setMonthlyDataRS] = useState({});
    const [monthlyDataNonRS, setMonthlyDataNonRS] = useState({});
    const [monthlyDataBar, setMonthlyDataBar] = useState({ bpjsCounts: {}, nonBpjsCounts: {} });
    const currentMonth = today.substring(0, 7);
    const startMonth = '2024-01';
    const currentYear = new Date().getFullYear();
    const [sexCount, setSexCount] = useState({ p: 0, l: 0 });
    const [topRecord, setTopRecord] = useState(null);
    const [drillDownData, setDrillDownData] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [patientHistory, setPatientHistory] = useState([]);


    const handleFetchData = async () => {
        setLoading(true);
        setError(null);
        try {

            const dataResponse = await fetchData(startDate, endDate);

            const formattedData = dataResponse.map(item => ({
                ...item,
                TGLREG: item.TGLREG.substring(0, 10),
            }));

            setData(formattedData || []);
            const todayRecords = formattedData.filter(item => item.TGLREG === today);
            setFilteredData(todayRecords);
            if (todayRecords.length > 0) {
                setTopRecord(todayRecords[0]);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleFetchData();
    }, [startDate, endDate]);

    useEffect(() => {
        processMonthlyData();
        countSexOccurrences(filteredData);
    }, [data, filteredData]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const displayedData = filteredData.filter(item =>
        item.NAMAPASIEN.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalFilteredCount = filteredData.length;

    const exportToExcel = () => {
        if (!filteredData || filteredData.length === 0) {
            alert('No data to export.');
            return;
        }

        const ws = XLSX.utils.json_to_sheet(filteredData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Data Pasien Rujukan');

        XLSX.writeFile(wb, 'Data_Pasien_Rujukan.xlsx');
    };

    const countSexOccurrences = (records) => {
        const counts = { p: 0, l: 0 };
        records.forEach(item => {
            if (item.JNSKEL === 'SEX.P') {
                counts.p += 1;
            } else if (item.JNSKEL === 'SEX.L') {
                counts.l += 1;
            }
        });
        setSexCount(counts);
    };

    const processMonthlyData = () => {
        const countsAll = {};
        const countsRS = {};
        const countsNonRS = {};

        const startDateObj = new Date(startMonth + '-01');
        const endDateObj = new Date(currentMonth + '-01');
        const monthList = [];

        while (startDateObj <= endDateObj) {
            const monthKey = startDateObj.toISOString().substring(0, 7);
            monthList.push(monthKey);
            countsAll[monthKey] = 0;
            countsRS[monthKey] = 0;
            countsNonRS[monthKey] = 0;
            startDateObj.setMonth(startDateObj.getMonth() + 1);
        }

        data.forEach(item => {
            const month = item.TGLREG.substring(0, 7);
            if (countsAll[month] !== undefined) {
                countsAll[month] += 1;
                if (item.Keterangan === 'Rumah Sakit Bhakti Asih Ciledug') {
                    countsRS[month] += 1;
                } else {
                    countsNonRS[month] += 1;
                }
            }
        });

        setMonthlyData(countsAll);
        setMonthlyDataRS(countsRS);
        setMonthlyDataNonRS(countsNonRS);
    };


    useEffect(() => {
        const newFilteredData = data.filter(item => {
            const itemDate = item.TGLREG.split('T')[0];
            return itemDate >= startDate && itemDate <= endDate;
        });
        setFilteredData(newFilteredData);
    }, [startDate, endDate, data]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const calculateDataForPieChart = (filteredData) => {
        const bpjsCount = filteredData.filter(item => item.NAMAPIHAK3.includes("BPJS KESEHATAN")).length;
        const nonBpjsCount = filteredData.length - bpjsCount;
        return [bpjsCount, nonBpjsCount];
    };

    const pieChartData = calculateDataForPieChart(filteredData);

    const dataForPieChart = {
        labels: ['BPJS', 'Lainnya'],
        datasets: [
            {
                data: pieChartData,
                backgroundColor: ['#050C9C', '#FF6500'],
                borderColor: ['#050C9C', '#FF6500'],
                borderWidth: 1,
                hoverOffset: 25,
            },
        ],
    };

    const optionsForPieChart = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 10,
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset.data;
                        const total = dataset.reduce((a, b) => a + b, 0);
                        const currentValue = dataset[tooltipItem.dataIndex];
                        const percentage = ((currentValue / total) * 100).toFixed(2) + '%';
                        return `${currentValue} (${percentage})`;
                    },
                },
            },
            legend: {
                display: true,
                position: 'right',
            },
        },
        cutout: '85%',
        onClick: (event, chartElement) => {
            if (chartElement.length > 0) {
                const index = chartElement[0].index;
                const label = dataForPieChart.labels[index];

                if (label === 'Lainnya') {
                    setModalDataPenjamin('Penjamin Non BPJS');
                    setOpenModalPenjamin(true);
                }
            }
        },
    };

    const calculateNonBpjsDetails = (filteredData) => {
        const nonBpjsDetails = {};
        filteredData.forEach(item => {
            const name = item.NAMAPIHAK3;
            const mainName = name.split(' ')[0];
            if (!name.includes("BPJS KESEHATAN")) {
                nonBpjsDetails[mainName] = (nonBpjsDetails[mainName] || 0) + 1;
            }
        });
        return nonBpjsDetails;
    };

    const nonBpjsData = calculateNonBpjsDetails(filteredData);
    const nonBpjsLabels = Object.keys(nonBpjsData);
    const nonBpjsCounts = Object.values(nonBpjsData);

    const dataForNonBpjsPieChart = {
        labels: nonBpjsLabels,
        datasets: [
            {
                data: nonBpjsCounts,
                backgroundColor: [
                    '#050C9C', '#FF6500', '#F1C40F', '#16A085', '#8E44AD',
                    '#E74C3C', '#3498DB', '#1ABC9C', '#9B59B6', '#2ECC71'
                ],
                borderColor: [
                    '#050C9C', '#FF6500', '#F1C40F', '#16A085', '#8E44AD',
                    '#E74C3C', '#3498DB', '#1ABC9C', '#9B59B6', '#2ECC71'
                ],
                borderWidth: 1,
                hoverOffset: 25,
            },
        ],
    };

    const optionsForNonBpjsPieChart = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 10,
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset.data;
                        const total = dataset.reduce((a, b) => a + b, 0);
                        const currentValue = dataset[tooltipItem.dataIndex];
                        const percentage = ((currentValue / total) * 100).toFixed(2) + '%';
                        return `${currentValue} (${percentage})`;
                    },
                },
            },
            legend: {
                display: true,
                position: 'right',
            },
        },
        cutout: '85%',
    };

    const calculateKeteranganData = (filteredData) => {
        const rsBhaktiCount = filteredData.filter(item => item.Keterangan === "Rumah Sakit Bhakti Asih Ciledug").length;
        const nonRsBhaktiCount = filteredData.length - rsBhaktiCount;
        return [rsBhaktiCount, nonRsBhaktiCount];
    };

    const keteranganChartData = calculateKeteranganData(filteredData);

    const dataForKeteranganPieChart = {
        labels: ['RS Bhakti Asih', 'Lainnya'],
        datasets: [
            {
                data: keteranganChartData,
                backgroundColor: ['#050C9C', '#48CFCB'],
                borderColor: ['#050C9C', '#48CFCB'],
                borderWidth: 1,
                hoverOffset: 25,
            },
        ],
    };

    const optionsForKeteranganPieChart = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 10,
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset.data;
                        const total = dataset.reduce((a, b) => a + b, 0);
                        const currentValue = dataset[tooltipItem.dataIndex];
                        const percentage = ((currentValue / total) * 100).toFixed(2) + '%';
                        return `${currentValue} (${percentage})`;
                    },
                },
            },
            legend: {
                display: true,
                position: 'right',
            },
        },
        cutout: '85%',
        onClick: (event, chartElement) => {
            if (chartElement.length > 0) {
                const index = chartElement[0].index;
                const label = dataForPieChart.labels[index];

                if (label === 'Lainnya') {
                    setModalDataRumahSakit('Rujukan Non RS Bhakti Asih');
                    setOpenModalRumahSakit(true);
                }
            }
        },
    };

    const calculateNonRsBhaktiDetails = (filteredData) => {
        const nonRsBhaktiDetails = {};
        filteredData.forEach(item => {
            const keterangan = item.Keterangan;
            if (keterangan !== "Rumah Sakit Bhakti Asih Ciledug") {
                nonRsBhaktiDetails[keterangan] = (nonRsBhaktiDetails[keterangan] || 0) + 1;
            }
        });
        return nonRsBhaktiDetails;
    };

    const nonRsBhaktiData = calculateNonRsBhaktiDetails(filteredData);
    const nonRsBhaktiLabels = Object.keys(nonRsBhaktiData);
    const nonRsBhaktiCounts = Object.values(nonRsBhaktiData);

    const dataForNonRsBhaktiPieChart = {
        labels: nonRsBhaktiLabels,
        datasets: [
            {
                data: nonRsBhaktiCounts,
                backgroundColor: [
                    '#050C9C', '#FF6500', '#F1C40F', '#16A085', '#8E44AD',
                    '#E74C3C', '#3498DB', '#1ABC9C', '#9B59B6', '#2ECC71'
                ],
                borderColor: [
                    '#050C9C', '#FF6500', '#F1C40F', '#16A085', '#8E44AD',
                    '#E74C3C', '#3498DB', '#1ABC9C', '#9B59B6', '#2ECC71'
                ],
                borderWidth: 1,
                hoverOffset: 25,
            },
        ],
    };

    const optionsForNonRsBhaktiPieChart = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 10,
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset.data;
                        const total = dataset.reduce((a, b) => a + b, 0);
                        const currentValue = dataset[tooltipItem.dataIndex];
                        const percentage = ((currentValue / total) * 100).toFixed(2) + '%';
                        return `${currentValue} (${percentage})`;
                    },
                },
            },
            legend: {
                display: true,
                position: 'right',
            },
        },
        cutout: '85%',
    }

    const calculateUnitPelayananData = (filteredData) => {
        const counts = { 'Pelayanan Medis (Gigi)': 0, 'Pelayanan Medis (Umum)': 0, 'Pelayanan Bidan dan Perawat': 0 };
        filteredData.forEach(item => {
            if (item.Unit_Pelayanan.includes("Gigi")) counts['Pelayanan Medis (Gigi)']++;
            else if (item.Unit_Pelayanan.includes("Umum")) counts['Pelayanan Medis (Umum)']++;
            else counts['Pelayanan Bidan dan Perawat']++;
        });
        return counts;
    };

    const unitPelayananData = calculateUnitPelayananData(filteredData);
    const fullUnitPelayananLabels = Object.keys(unitPelayananData);
    const unitPelayananLabels = Object.keys(unitPelayananData).map(label =>
        label.replace("Pelayanan", "").trim()
    )
    const unitPelayananCounts = Object.values(unitPelayananData);

    const dataForUnitPelayananPieChart = {
        labels: unitPelayananLabels,
        datasets: [{
            data: unitPelayananCounts,
            backgroundColor: ['#050C9C', '#FF6500', '#F1C40F'],
            borderColor: ['#050C9C', '#FF6500', '#F1C40F'],
            borderWidth: 1,
            hoverOffset: 25,
        }],
    };

    const optionsForUnitPelayananPieChart = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 10,
            }
        },
        onClick: (event, elements) => {
            if (elements.length) {
                const index = elements[0].index;
                const category = fullUnitPelayananLabels[index];
                handlePieChartClick(category);
            }
        },
        plugins: {
            legend: { display: true, position: 'right' },
        },
        cutout: '85%',
    };

    const handlePieChartClick = (category) => {
        setSelectedCategory(category);

        const filteredByCategory = filteredData.filter(item =>
            item.Unit_Pelayanan.toUpperCase().includes(category.replace("Pelayanan Medis (", "").replace(")", "").toUpperCase())
        );

        const dpjpCounts = filteredByCategory.reduce((acc, item) => {
            const dpjp = item.DPJP || 'Lainnya';
            acc[dpjp] = (acc[dpjp] || 0) + 1;
            return acc;
        }, {});

        if (Object.keys(dpjpCounts).length === 0) {
            console.warn("No DPJP data found for the selected category.");
        }

        setDrillDownData({
            labels: Object.keys(dpjpCounts),
            datasets: [{
                data: Object.values(dpjpCounts),
                backgroundColor: ['#E74C3C', '#3498DB', '#1ABC9C', '#9B59B6', '#2ECC71'],
                borderColor: ['#E74C3C', '#3498DB', '#1ABC9C', '#9B59B6', '#2ECC71'],
                borderWidth: 1,
                hoverOffset: 25,
            }],
        });
        setOpenModalPelayanan(true);
    };

    const optionsForBreakdownPieChart = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: true, position: 'right' },
        },
        cutout: '85%',
    };

    const calculateAgeGroupData = (filteredData) => {
        const ageGroupCounts = {
            'Bayi&Balita (<5)': 0,
            'Anak-anak (5-9)': 0,
            'Remaja (10-18)': 0,
            'Dewasa (19-59)': 0,
            'Lansia (60+)': 0,
        };

        filteredData.forEach(item => {
            const age = item.UMUR;
            if (age < 5) ageGroupCounts['Bayi&Balita (<5)'] += 1;
            else if (age >= 5 && age <= 9) ageGroupCounts['Anak-anak (5-9)'] += 1;
            else if (age >= 10 && age <= 18) ageGroupCounts['Remaja (10-18)'] += 1;
            else if (age >= 19 && age <= 59) ageGroupCounts['Dewasa (19-59)'] += 1;
            else if (age >= 60) ageGroupCounts['Lansia (60+)'] += 1;
        });

        return ageGroupCounts;
    };

    const ageGroupData = calculateAgeGroupData(filteredData);
    const ageGroupLabels = Object.keys(ageGroupData);
    const ageGroupCounts = Object.values(ageGroupData);

    const dataForAgeGroupPieChart = {
        labels: ageGroupLabels,
        datasets: [
            {
                data: ageGroupCounts,
                backgroundColor: [
                    '#050C9C', '#FF6500', '#F1C40F', '#16A085', '#8E44AD',
                    '#E74C3C', '#3498DB', '#1ABC9C', '#9B59B6', '#2ECC71'
                ],
                borderColor: [
                    '#050C9C', '#FF6500', '#F1C40F', '#16A085', '#8E44AD',
                    '#E74C3C', '#3498DB', '#1ABC9C', '#9B59B6', '#2ECC71'
                ],
                borderWidth: 1,
                hoverOffset: 25,
            },
        ],
    };

    const optionsForAgePieChart = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 10,
                bottom: 10,
            }
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const dataset = tooltipItem.dataset.data;
                        const total = dataset.reduce((a, b) => a + b, 0);
                        const currentValue = dataset[tooltipItem.dataIndex];
                        const percentage = ((currentValue / total) * 100).toFixed(2) + '%';
                        return `${currentValue} (${percentage})`;
                    },
                },
            },
            legend: {
                display: true,
                position: 'right',
            },
        },
        cutout: '85%',
    };

    const chartLabels = Object.keys(monthlyData).map(monthKey => {
        const [month] = monthKey.split('-');
        return `${monthNames[parseInt(month) - 1]}`;
    });

    const chartData = {
        labels: chartLabels,
        datasets: [
            {
                label: 'Jumlah Pasien Dirujuk (Semua)',
                data: Object.values(monthlyData),
                fill: true,
                backgroundColor: 'rgba(75, 192, 192, 0.1)',
                borderColor: 'rgba(75, 192, 192, 1)',
                tension: 0.4,
            },
            {
                label: 'Rumah Sakit Bhakti Asih Ciledug',
                data: Object.values(monthlyDataRS),
                fill: true,
                backgroundColor: 'rgba(5, 12, 156, 0.1)',
                borderColor: '#050C9C',
                tension: 0.4,
            },
            {
                label: 'Lainnya',
                data: Object.values(monthlyDataNonRS),
                fill: true,
                backgroundColor: 'rgba(255, 101, 0, 0.1)',
                borderColor: '#FF6500',
                tension: 0.4,
            },
        ],
    };

    const processMonthlyDataBar = () => {
        const bpjsCounts = {};
        const nonBpjsCounts = {};

        const startDateObj = new Date(startMonth + '-01');
        const endDateObj = new Date(currentMonth + '-01');

        const monthList = [];
        while (startDateObj <= endDateObj) {
            const monthKey = startDateObj.toISOString().substring(0, 7);
            monthList.push(monthKey);
            bpjsCounts[monthKey] = 0;
            nonBpjsCounts[monthKey] = 0;
            startDateObj.setMonth(startDateObj.getMonth() + 1);
        }

        data.forEach(item => {
            const month = item.TGLREG.substring(0, 7);
            if (monthList.includes(month)) {
                if (item.NAMAPIHAK3.includes("BPJS KESEHATAN")) {
                    bpjsCounts[month] += 1;
                } else {
                    nonBpjsCounts[month] += 1;
                }
            }
        });

        setMonthlyDataBar({ bpjsCounts, nonBpjsCounts });
    };

    const prepareChartData = () => {
        const { bpjsCounts, nonBpjsCounts } = monthlyDataBar;

        const labels = Object.keys(bpjsCounts).map(monthKey => {
            const monthIndex = new Date(monthKey).getMonth();
            return monthNames[monthIndex];
        });

        const bpjsData = Object.values(bpjsCounts);
        const nonBpjsData = Object.values(nonBpjsCounts);

        const chartData = {
            labels: labels.length ? labels : [],
            datasets: [
                {
                    label: 'BPJS Kesehatan',
                    data: bpjsData.length ? bpjsData : [],
                    backgroundColor: '#050C9C',
                    borderColor: '#050C9C',
                    borderWidth: 1,
                },
                {
                    label: 'Lainnya',
                    data: nonBpjsData.length ? nonBpjsData : [],
                    backgroundColor: '#FF6500',
                    borderColor: '#FF6500',
                    borderWidth: 1,
                },
            ],
        };

        return chartData;
    };

    const getIconColorAndBg = (gender) => {
        if (gender === 'SEX.P') {
            return { iconColor: '#f48fb1', bgColor: '#ffebee' };
        } else if (gender === 'SEX.L') {
            return { iconColor: '#050C9C', bgColor: '#E0E4FA' };
        }
        return { iconColor: '#333', bgColor: '#f0f0f0' };
    };

    useEffect(() => {
        processMonthlyDataBar();
    }, [data]);

    const chartDataBar = prepareChartData();

    const handleCloseModalRumahSakit = () => {
        setOpenModalRumahSakit(false);
    };

    const handleCloseModalPenjamin = () => {
        setOpenModalPenjamin(false);
    };

    const handleCloseModalPelayanan = () => {
        setOpenModalPelayanan(false);
    };

    const handleCloseModalPasien = () => {
        setOpenModalPasien(false);
        setModalDataPasien(null);
        setPatientHistory([]);
    };

    const handleCloseModalNotifikasi = () => {
        setOpenModalNotifikasi(false);
    };

    const openModalPasienClick = async (row) => {
        setModalDataPasien(row);
        setOpenModalPasien(true);

        try {
            const historyResponse = await fetchPasienRujukanHistory(row.NOPASIEN);
            setPatientHistory(historyResponse || []);
        } catch (err) {
            console.error("Failed to fetch patient history:", err);
        }
    };

    const openModalNotifikasiClick = () => {
        setOpenModalNotifikasi(true);
    };

    const handleRefresh = () => {
        window.location.reload();
    };


    return (
        <Box >
            <Box sx={{
                width: '100%',
                height: '50px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Typography variant="h6" sx={{
                    color: '#333',
                    fontWeight: 'bold',
                }}>
                    Data Pasien Rujukan
                </Typography>
                <Box sx={{ display: 'flex', gap: 1.5 }}>
                    <Tooltip2 title="Download" arrow>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#fff',
                                borderRadius: '50%',
                                width: '36px',
                                height: '36px',
                                boxShadow: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={exportToExcel}
                        >
                            <DownloadIcon sx={{ color: '#333' }} />
                        </Box>
                    </Tooltip2>
                    <Tooltip2 title="Notifications" arrow>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#fff',
                                borderRadius: '50%',
                                width: '36px',
                                height: '36px',
                                boxShadow: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={openModalNotifikasiClick}
                        >
                            <NotificationsIcon sx={{ color: '#333' }} />
                        </Box>
                    </Tooltip2>
                    <Tooltip2 title="Refresh" arrow>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#fff',
                                borderRadius: '50%',
                                width: '36px',
                                height: '36px',
                                boxShadow: 'none',
                                cursor: 'pointer',
                            }}
                            onClick={handleRefresh}
                        >
                            <RefreshIcon sx={{ color: '#333' }} />
                        </Box>
                    </Tooltip2>
                </Box>
            </Box>
            <Box sx={{ marginTop: 2 }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={4} >
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: 'none',
                            boxShadow: 'none',
                            gap: 1,
                            padding: 2,
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 2,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Periode
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <TextField
                                    label="Start Date"
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            height: '1.5rem',
                                            padding: '10px',
                                        },
                                        width: '185px',
                                    }}
                                />
                                <Typography sx={{ color: '#333' }}>s.d</Typography>
                                <TextField
                                    label="End Date"
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            height: '1.5rem',
                                            padding: '10px',
                                        },
                                        width: '185px',
                                    }}
                                />
                            </Box>
                        </Card>
                    </Grid2>
                    <Grid2 size={2}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            padding: 2,
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Total Dirujuk
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 3,
                                marginTop: 1,
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#E0E4FA',
                                    color: '#050C9C',
                                    borderRadius: '50%',
                                    width: '52px',
                                    height: '52px',
                                }}>
                                    <FontAwesomeIcon icon={faUserGroup} />
                                </Box>
                                <Typography variant="h5" sx={{
                                    color: '#333',
                                    fontWeight: 'bold'
                                }}>
                                    {totalFilteredCount}
                                </Typography>
                            </Box>
                        </Card>
                    </Grid2>
                    <Grid2 size={2}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            padding: 2,
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Jenis Kelamin
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1.5,
                                marginTop: 1,
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#FFE5CC',
                                    color: '#FF6500',
                                    borderRadius: '50%',
                                    width: '52px',
                                    height: '52px',
                                }}>
                                    <FontAwesomeIcon icon={faVenusMars} />
                                </Box>
                                <Typography variant="h5" sx={{
                                    color: '#333',
                                    fontWeight: 'bold',
                                }}>
                                    {sexCount.p}P
                                </Typography>
                                <Typography variant="h5" sx={{
                                    color: '#333',
                                    fontWeight: 'bold',
                                    marginLeft: -1
                                }}>
                                    /
                                </Typography>
                                <Typography variant="h5" sx={{
                                    color: '#333',
                                    fontWeight: 'bold',
                                    marginLeft: -1
                                }}>
                                    {sexCount.l}L
                                </Typography>
                            </Box>
                        </Card>
                    </Grid2>
                    <Grid2 size={4}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            display: 'flex',
                            flexDirection: 'column',
                            padding: 2,
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Rujukan Terakhir
                            </Typography>
                            {topRecord ? (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    marginTop: 1,
                                }}>
                                    {(() => {
                                        const { iconColor, bgColor } = getIconColorAndBg(topRecord.JNSKEL);
                                        return (
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: bgColor,
                                                color: iconColor,
                                                borderRadius: '50%',
                                                width: '52px',
                                                height: '52px',
                                            }}>
                                                <FontAwesomeIcon icon={faUser} />
                                            </Box>
                                        );
                                    })()}
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: 0.5,
                                    }}>
                                        <Typography variant="h6" sx={{
                                            color: '#333',
                                            fontWeight: 'normal',
                                            fontSize: '0.9rem',
                                        }}>
                                            {topRecord.NAMAPASIEN}
                                        </Typography>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 2,
                                        }}>
                                            <Typography variant="body1" sx={{
                                                color: '#333',
                                                fontWeight: 'normal',
                                                fontSize: '0.70rem',
                                            }}>
                                                {topRecord.NAMAPIHAK3}
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: '#333',
                                                fontWeight: 'normal',
                                                fontSize: '0.70rem',
                                                marginLeft: -1.5
                                            }}>
                                                -
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                color: '#333',
                                                fontWeight: 'normal',
                                                fontSize: '0.75rem',
                                                marginLeft: -1.5
                                            }}>
                                                {topRecord.Keterangan}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ) : (
                                <Typography variant="body1" sx={{ color: '#888' }}>
                                    Tidak ada rujukan hari ini . . .
                                </Typography>
                            )}
                        </Card>
                    </Grid2>
                </Grid2>
            </Box>
            <Box sx={{ marginTop: 2 }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={3}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            padding: 2,
                            height: '175px'
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Penjamin
                            </Typography>
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Doughnut data={dataForPieChart} options={optionsForPieChart}
                                    style={{ width: '100%', height: '100%', padding: 5 }}
                                />
                            </Box>
                        </Card>
                    </Grid2>
                    <Grid2 size={3}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            padding: 2,
                            height: '175px'
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Rumah Sakit
                            </Typography>
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Doughnut data={dataForKeteranganPieChart} options={optionsForKeteranganPieChart}
                                    style={{ width: '100%', height: '100%', padding: 5 }}
                                />
                            </Box>
                        </Card>
                    </Grid2>
                    <Grid2 size={3}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            padding: 2,
                            height: '175px'
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Pelayanan
                            </Typography>
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Doughnut data={dataForUnitPelayananPieChart} options={optionsForUnitPelayananPieChart}
                                    style={{ width: '100%', height: '100%', padding: 5 }} />
                            </Box>
                        </Card>
                    </Grid2>
                    <Grid2 size={3}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            padding: 2,
                            height: '175px'
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Kategori Usia
                            </Typography>
                            <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Doughnut data={dataForAgeGroupPieChart} options={optionsForAgePieChart}
                                    style={{ width: '100%', height: '100%', padding: 5 }} />
                            </Box>
                        </Card>
                    </Grid2>
                </Grid2>
            </Box>
            <Box sx={{ overflow: 'hidden', marginTop: 2 }}>
                <Grid2 container spacing={2}>
                    <Grid2 size={6}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            height: '370px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            padding: 2
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Data Pasien Dirujuk Per Bulan
                            </Typography>
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Line data={chartData} options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            title: {
                                                display: true,
                                                text: `${currentYear}`,
                                            },
                                        },
                                        y: {
                                            title: {
                                                display: true,
                                                text: 'Jumlah Pasien Dirujuk',
                                            },
                                        },
                                    },
                                }} />
                            </Box>
                        </Card>
                    </Grid2>
                    <Grid2 size={6}>
                        <Card sx={{
                            backgroundColor: '#FFFFFF',
                            border: 'none',
                            boxShadow: 'none',
                            height: '370px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            padding: 2,
                        }}>
                            <Typography variant="h7" sx={{
                                fontWeight: 'bold',
                                marginBottom: 1,
                                color: '#333',
                                textAlign: 'left',
                                width: '100%'
                            }}>
                                Data Penjamin Per Bulan
                            </Typography>
                            <Box sx={{ width: '100%', height: '100%' }}>
                                <Bar
                                    data={chartDataBar}
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            x: {
                                                title: {
                                                    display: true,
                                                    text: `${currentYear}`,
                                                },
                                            },
                                            y: {
                                                title: {
                                                    display: true,
                                                    text: 'Jumlah Penjamin',
                                                },
                                                beginAtZero: true,
                                            },
                                        },
                                        plugins: {
                                            tooltip: {
                                                callbacks: {
                                                    label: (tooltipItem) => {
                                                        const { dataset, dataIndex } = tooltipItem;
                                                        const count = dataset.data[dataIndex];
                                                        return `${dataset.label}: ${count}`;
                                                    },
                                                },
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Card>
                    </Grid2>
                </Grid2>
            </Box>
            <Box>
                <Box sx={{ marginTop: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextField
                        placeholder="Cari Nama Pasien"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{
                            width: '300px',
                            backgroundColor: '#FFF',
                            boxShadow: 'none',
                            borderRadius: '5px',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    border: 'none',
                                },
                            },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />

                </Box>
                {loading ? (
                    <p>Loading...</p>
                ) : error ? (
                    <p>Error: {error}</p>
                ) : (
                    <Card sx={{
                        backgroundColor: '#FFFFFF',
                        border: 'none',
                        boxShadow: 'none',
                        marginTop: 2
                    }}>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {displayedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={`${row.NOPASREG}`}>
                                            {columns.map((column) => {
                                                if (column.id === 'actions') {
                                                    return (
                                                        <TableCell key={column.id} sx={{ display: 'flex' }}>
                                                            <Tooltip2 title="Preview" arrow>
                                                                <IconButton sx={{ marginLeft: -1.5 }}
                                                                    onClick={() => openModalPasienClick(row)}  >
                                                                    <FontAwesomeIcon icon={faEye} />
                                                                </IconButton>
                                                            </Tooltip2>
                                                            <Tooltip2 title="Transfer CRM" arrow>
                                                                <IconButton>
                                                                    <FontAwesomeIcon icon={faPaperPlane} />
                                                                </IconButton>
                                                            </Tooltip2>
                                                        </TableCell>
                                                    );
                                                }
                                                return (
                                                    <TableCell key={column.id}>{row[column.id]}</TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={displayedData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                )}
            </Box>
            <Modal
                open={openModalRumahSakit}
                onClose={handleCloseModalRumahSakit}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#FFFFFF',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '5px',
                    p: 4,
                    width: 800,
                    border: 'none',
                    outline: 'none',
                }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        {modalDataRumahSakit}
                    </Typography>
                    {nonRsBhaktiLabels.length > 0 && (
                        <Box key="nonRsBhaktiChart" sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'auto',
                            marginTop: 3
                        }}>
                            <Doughnut
                                data={dataForNonRsBhaktiPieChart}
                                options={optionsForNonRsBhaktiPieChart}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </Box>
                    )}
                </Box>
            </Modal>
            <Modal
                open={openModalPenjamin}
                onClose={handleCloseModalPenjamin}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: '#FFFFFF',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '5px',
                    p: 4,
                    width: 800,
                    border: 'none',
                    outline: 'none',
                }}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        {modalDataPenjamin}
                    </Typography>
                    {nonBpjsLabels.length > 0 && (
                        <Box key="nonBpjsChart" sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Doughnut
                                data={dataForNonBpjsPieChart}
                                options={optionsForNonBpjsPieChart}
                                style={{ width: '100%', height: '100%', padding: 10 }}
                            />
                        </Box>
                    )}
                </Box>
            </Modal>
            {drillDownData && (
                <Modal
                    open={openModalPelayanan}
                    onClose={handleCloseModalPelayanan}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: '#FFFFFF',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                        borderRadius: '5px',
                        p: 4,
                        width: 800,
                        border: 'none',
                        outline: 'none',
                    }}>
                        <Typography variant="h7" sx={{ fontWeight: 'bold', marginBottom: 1, color: '#333' }}>
                            DPJP Breakdown for {selectedCategory}
                        </Typography>
                        <Box sx={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            overflow: 'auto',
                            marginTop: 3
                        }}>
                            <Doughnut
                                data={drillDownData}
                                options={optionsForBreakdownPieChart}
                                style={{ width: '100%', height: '100%' }}
                            />
                        </Box>
                    </Box>
                </Modal>
            )}
            <Modal
                open={openModalPasien}
                onClose={handleCloseModalPasien}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '3%',
                    right: '2%',
                    transform: 'translate(0, 0)',
                    bgcolor: '#FFFFFF',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    p: 4,
                    width: '400px',
                    height: '100%',
                    maxHeight: '86vh',
                    overflow: 'hidden',
                    border: 'none',
                    outline: 'none',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <Box sx={{
                        height: '300px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        {modalDataPasien && (
                            <>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100px',
                                        height: '100px',
                                        mb: 2,
                                        bgcolor: modalDataPasien.JNSKEL === 'SEX.P' ? '#ffebee' : '#e3f2fd',
                                        borderRadius: '50%',

                                        color: modalDataPasien.JNSKEL === 'SEX.P' ? '#f48fb1' : '#64b5f6',
                                    }}
                                >
                                    <FontAwesomeIcon icon={faUser} size="4x" />
                                </Box>
                                <Typography variant="h7" sx={{ textAlign: 'center', marginTop: 1 }}>
                                    {modalDataPasien.NAMAPASIEN}
                                </Typography>
                                <Typography variant="body2" sx={{ color: '#333' }}>#{modalDataPasien.NIK}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: 2 }}>
                                    <FontAwesomeIcon icon={faPhone} style={{ fontSize: '0.9rem', color: '#333' }} />
                                    <Typography variant="body2" sx={{ color: '#333' }}>{modalDataPasien.NOHP}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: 1 }}>
                                    <FontAwesomeIcon icon={faCalendarDay} style={{ fontSize: '0.9rem', color: '#333' }} />
                                    <Typography variant="body2" sx={{ color: '#333' }}>  {new Date(modalDataPasien.TGLLAHIR).toISOString().split('T')[0]}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: 1 }}>
                                    <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '0.9rem', color: '#333' }} />
                                    <Typography variant="body2" sx={{ color: '#333' }}>{modalDataPasien.ALAMAT}</Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                    <Box sx={{ mt: 2, overflowY: 'auto' }}>
                        <Divider sx={{ my: 1 }} />
                        <Typography variant="h7" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 3, mb: 3 }} >Riwayat Rujukan</Typography>
                        {patientHistory.length > 0 ? (
                            patientHistory.map((history, index) => (
                                <Accordion key={index} sx={{ mt: 1.5, border: 'none', boxShadow: 'none' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}-content`}
                                        id={`panel${index}-header`}
                                    >
                                        <Typography variant="body2">
                                            {new Date(history.TGLREG).toISOString().split('T')[0] === new Date().toISOString().split('T')[0]
                                                ? 'Hari Ini'
                                                : new Date(history.TGLREG).toISOString().split('T')[0]}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography variant="body2"><b>Unit Pelayanan:</b> {history.Unit_Pelayanan}</Typography>
                                        <Typography variant="body2"><b>Paramedis:</b> {history.DPJP}</Typography>
                                        <Typography variant="body2" sx={{ mt: 2 }}></Typography>
                                        <Typography variant="body2"><b>SOAP Dokter:</b></Typography>
                                        <Typography variant="body2"><b>S</b></Typography>
                                        <Typography variant="body2">{history.SOAP_S_Dokter}</Typography>
                                        <Typography variant="body2"><b>O</b></Typography>
                                        <Typography variant="body2">{history.SOAP_O_Dokter}</Typography>
                                        <Typography variant="body2"><b>A</b></Typography>
                                        <Typography variant="body2">{history.SOAP_A_Dokter}</Typography>
                                        <Typography variant="body2"><b>P</b></Typography>
                                        <Typography variant="body2">{history.SOAP_P_Dokter}</Typography>
                                        <Typography variant="body2" sx={{ mt: 2 }}></Typography>
                                        <Typography variant="body2"><b>SOAP Perawat:</b></Typography>
                                        <Typography variant="body2"><b>S</b></Typography>
                                        <Typography variant="body2">{history.SOAP_S_Nurse}</Typography>
                                        <Typography variant="body2"><b>O</b></Typography>
                                        <Typography variant="body2">{history.SOAP_O_Nurse}</Typography>
                                        <Typography variant="body2"><b>A</b></Typography>
                                        <Typography variant="body2">{history.SOAP_A_Nurse}</Typography>
                                        <Typography variant="body2"><b>P</b></Typography>
                                        <Typography variant="body2">{history.SOAP_P_Nurse}</Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        ) : (
                            <Typography variant="body2" sx={{ mt: 2 }}>Tidak ada riwayat rujukan.</Typography>
                        )}
                    </Box>
                </Box>
            </Modal>
            <Modal
                open={openModalNotifikasi}
                onClose={handleCloseModalNotifikasi}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '3%',
                    right: '2%',
                    transform: 'translate(0, 0)',
                    bgcolor: '#FFFFFF',
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                    borderRadius: '10px',
                    p: 4,
                    width: '400px',
                    height: '100%',
                    maxHeight: '86vh',
                    overflow: 'auto',
                    border: 'none',
                    outline: 'none',
                }}>
                    {/* Modal content goes here */}
                </Box>
            </Modal>
        </Box>
    );
};

export default DataPerujuk;