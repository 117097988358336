import React from 'react';
import Pipeline from '../../components/crm/Pipeline';
import Layout from '../../pages/crm/Layout';

export const PipelinePage = () => {
    return (
        <Layout>
            <Pipeline />
        </Layout>
    )
}
export default PipelinePage;