const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const apiRequest = async (url, method, body = null) => {
    const headers = {
        'Content-Type': 'application/json',
    };

    const options = {
        method,
        headers,
        credentials: 'include',
    };

    if (body) {
        options.body = JSON.stringify(body);
    }

    try {
        const response = await fetch(url, options);
        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'Something went wrong');
        }

        return data;
    } catch (error) {
        throw error;
    }
};

export const login = async (email, password, recaptchaToken) => {
    const url = `${API_BASE_URL}/api/v1/auth/signin`;
    const body = { email, password, recaptchaToken };

    try {
        const response = await apiRequest(url, 'POST', body);

        if (response && response.accessToken) {
            document.cookie = `accessToken=${response.accessToken}; path=/; max-age=3600`;
            return response;
        } else {
            console.error('No access token in response');
            throw new Error('No access token received');
        }
    } catch (error) {
        console.error('Login API request failed:', error);
        throw new Error(error.message || 'Login failed');
    }
};