import React from 'react';
import DataPerujuk from '../../components/klinik/DataPerujuk';
import Layout from '../../pages/klinik/Layout';

export const DataPerujukPage = () => {
    return (
        <Layout>
            <DataPerujuk />
        </Layout>
    )
}
export default DataPerujukPage;