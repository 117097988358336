import React from 'react';
import { Box, Grid2, Card } from '@mui/material';

const Pipeline = () => {
    return (
        <Box>
            <Box>
                <Grid2 container spacing={2}>
                    <Grid2 size={3}>
                        <Card sx={{
                            backgroundColor: '#ffffff',
                            height: '80px',
                        }}>

                        </Card>
                    </Grid2>
                    <Grid2 size={3}>
                        <Card sx={{
                            backgroundColor: '#ffffff',
                            height: '80px',
                        }}>

                        </Card>
                    </Grid2>
                    <Grid2 size={3}>
                        <Card sx={{
                            backgroundColor: '#ffffff',
                            height: '80px',
                        }}>

                        </Card>
                    </Grid2>
                    <Grid2 size={3}>
                        <Card sx={{
                            backgroundColor: '#ffffff',
                            height: '80px',
                        }}>

                        </Card>
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    );
};

export default Pipeline;
