import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Background from '../../assets/master/background.jpg';
import LogoRumahSakit from '../../assets/master/logo-rs.png';
import { login } from '../../api/authAPI';

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError('');

        try {
            const response = await login(email, password);

            if (response && response.accessToken) {
                navigate('/dashboard');
            } else {
                setError('Login failed. No access token received.');
            }
        } catch (error) {
            console.error('Login failed:', error);
            setError(error.message || 'Login failed. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box sx={{ overflow: 'hidden', height: '100vh' }}>
            <Box sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: `url(${Background})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
            }}>
                <Card sx={{
                    width: { xs: '75%', md: '25%' },
                    height: '75%',
                    backgroundColor: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    padding: 4,
                }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%'
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            gap: 2,
                        }}>
                            <img
                                src={LogoRumahSakit}
                                alt="RSU Bhakti Asih Logo"
                                style={{ height: '100px' }}
                            />
                        </Box>
                        <Typography
                            component="h1"
                            variant="h5"
                            sx={{
                                fontSize: 'clamp(1.2rem, 4vw, 1.5rem)',
                                textAlign: 'center',
                                marginTop: 3
                            }}
                        >
                            Sign In To Admin
                        </Typography>
                    </Box>

                    {error && (
                        <Box sx={{
                            backgroundColor: '#ffe6e6',
                            padding: 1,
                            borderRadius: 1,
                            marginTop: 1,
                        }}>
                            <Typography
                                component="p"
                                color="error"
                                sx={{ textAlign: 'center' }}
                            >
                                {error}
                            </Typography>
                        </Box>
                    )}

                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleLogin}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                            marginTop: 5,
                        }}
                    >
                        <FormControl>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <TextField
                                id="email"
                                type="email"
                                name="email"
                                placeholder="your@bhaktiasih.co.id"
                                autoComplete="email"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                size='small'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={error && !email}
                                sx={{
                                    marginTop: 1,
                                    borderRadius: '5px',
                                    backgroundColor: '#f3f6f9',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                            borderRadius: '5px',
                                        }
                                    },
                                }}
                            />
                        </FormControl>
                        <FormControl>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <FormLabel htmlFor="password">Password</FormLabel>
                            </Box>
                            <TextField
                                name="password"
                                placeholder="••••••"
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                required
                                fullWidth
                                variant="outlined"
                                size='small'
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={error && !password}
                                sx={{
                                    marginTop: 1,
                                    borderRadius: '5px',
                                    backgroundColor: '#f3f6f9',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                            borderRadius: '5px',
                                        }
                                    },
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword((prev) => !prev)}
                                                edge="end"
                                                aria-label="toggle password visibility"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                borderRadius: '10px',
                                textTransform: 'none',
                                marginTop: 2,
                                height: '40px',
                                background: 'linear-gradient(to top, #1fa345, #66cc66)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign in'}
                        </Button>
                    </Box>
                </Card>
            </Box>
        </Box>
    );
};

export default Login;