import React from "react";
import Navbar from '../../components/klinik/Navbar';

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <div style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
                <Navbar />
                <main style={{ flexGrow: 1, overflowY: 'auto', padding: '20px', backgroundColor: '#F7F7FD' }}>
                    {children}
                </main>
            </div>
        </React.Fragment>
    );
};

export default Layout;