import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const Dashboard = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#fff',
                textAlign: 'center',
                flexDirection: 'column',
            }}
        >
            <ErrorOutlineIcon style={{ width: '100px', height: '100px' }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                We're currently undergoing maintenance
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 3 }}>
                Our website is currently undergoing scheduled maintenance. Please check back later.
            </Typography>
        </Box>
    );
};

export default Dashboard;
