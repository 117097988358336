import React, { useEffect, useState, useCallback } from 'react';
import {
    Box,
    Typography,
    IconButton,
    AppBar,
    Toolbar,
    Menu,
    Container,
    Tooltip,
    MenuItem,
    Card,
    CardActionArea,
    CardMedia
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import Background from '../../assets/master/background.jpg';
import Logo from '../../assets/master/logo-rs.png';
import IconCRM from '../../assets/master/crm.png';
import IconWebsite from '../../assets/master/website.png';
import IconKars from '../../assets/master/kars.png';
import IconKoperasi from '../../assets/master/koperasi.png';
import IconPreparemom from '../../assets/master/preparemom.png';
import IconKlinik from '../../assets/master/klinik.png';
import IconAdminWeb from '../../assets/master/admin-web.png';
import IconEmail from '../../assets/master/email.png';

const settings = ['Profile', 'Logout'];

const cardData = [
    { icon: IconKlinik, bgColor: '#EA906C', label: 'Klinik', link: '/klinik/data-rujukan' },
    { icon: IconCRM, bgColor: '#536493', label: 'CRM', link: '/crm/pipeline' },
    { icon: IconAdminWeb, bgColor: '#8ABFA3', label: 'Admin Web', link: '/admin-web/dashboard' },
    { icon: IconKoperasi, bgColor: '#624E88', label: 'Koperasi', link: 'https://koperasi.bhaktiasih.co.id' },
    { icon: IconKars, bgColor: '#bfb56b', label: 'Sidokar', link: 'https://sidokar.bhaktiasih.co.id' },
    { icon: IconPreparemom, bgColor: '#cb6483', label: 'Preparemom', link: 'https://preparemom.bhaktiasih.co.id' },
    { icon: IconWebsite, bgColor: '#A5BECC', label: 'Website', link: 'https://rsbhaktiasih.com' },
    { icon: IconEmail, bgColor: '#9D5353', label: 'Email', link: 'https://mail.hostinger.com/' },
];

export const Dashboard = () => {
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();

    const [timeout, setTimeoutState] = useState(null);

    const handleLogout = useCallback(() => {
        localStorage.clear();
        navigate('/');
    }, [navigate]);

    const handleOpenUserMenu = (event) => setAnchorElUser(event.currentTarget);
    const handleCloseUserMenu = () => setAnchorElUser(null);

    const handleCardClick = (link) => window.open(link, '_blank', 'width=800,height=600');

    const resetInactivityTimer = useCallback(() => {
        clearTimeout(timeout);
        const newTimeout = setTimeout(() => {
            handleLogout();
        }, 300000);
        setTimeoutState(newTimeout);
    }, [timeout, handleLogout]);

    useEffect(() => {
        const handleUserActivity = () => {
            resetInactivityTimer();
        };

        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);
        window.addEventListener('click', handleUserActivity);

        resetInactivityTimer();

        return () => {
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
            window.removeEventListener('click', handleUserActivity);
            clearTimeout(timeout);
        };
    }, [resetInactivityTimer, timeout]);

    return (
        <Box sx={{ height: '100vh', overflow: 'hidden' }}>
            <AppBar position="absolute" sx={{ background: 'transparent', color: '#00712D', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <img src={Logo} alt="Logo" style={{ width: 60, marginRight: 8 }} />
                        <Typography
                            variant="h6"
                            component="a"
                            href="/"
                            sx={{
                                ml: 2,
                                fontSize: '28px',
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                color: 'inherit',
                                textDecoration: 'none'
                            }}
                        >
                            RS BHAKTI ASIH
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Tooltip title="Profile">
                            <IconButton onClick={handleOpenUserMenu}>
                                <AccountCircleIcon sx={{ fontSize: 35, color: '#00712D' }} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            anchorEl={anchorElUser}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            {settings.map((setting) => (
                                <MenuItem
                                    key={setting}
                                    onClick={setting === 'Logout' ? handleLogout : handleCloseUserMenu}
                                >
                                    <Typography textAlign="center">{setting}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box
                sx={{
                    height: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: `url(${Background})`,
                    backgroundSize: 'cover',
                    position: 'relative',
                    overflowY: 'auto'
                }}
            >
                <Box sx={{ position: 'absolute', width: '100%', height: '100%', bgcolor: '#BEADFA', opacity: 0.2, zIndex: 1 }} />
                <Box sx={{ zIndex: 2, position: 'relative', maxWidth: '700px', width: '100%', mt: { xs: '750px', sm: '100px', md: '55px' } }}>
                    <Grid container spacing={8} justifyContent="center" >
                        {cardData.map((card, index) => (
                            <Grid key={index} item xs={12} sm={6} md={3} display="flex" flexDirection="column" alignItems="center">
                                <Card sx={{ width: 100, height: 100, backgroundColor: card.bgColor }}>
                                    <CardActionArea onClick={() => handleCardClick(card.link)} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <CardMedia component="img" image={card.icon} alt="icon" sx={{ height: 65, width: 65, objectFit: 'contain' }} />
                                    </CardActionArea>
                                </Card>
                                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                    {card.label}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;